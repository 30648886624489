import React from 'react'

const CompanyProfile = () => {
  return (
    <div>
        I am company profile page
      
    </div>
  )
}

export default CompanyProfile
