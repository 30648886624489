
import './style.css'
import Landing from './views/home'

const TeleportLanding = () => {
  return (
        <Landing/>
  )
}

export default TeleportLanding