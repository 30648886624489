import React from 'react'
import "./loader.css"

const loader = () => {
  return (
    <div style={{margin:"100px 10px"}}>

    <div class="container">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>
    </div>
  )
}

export default loader
