import React from 'react'
import { Container } from '@mui/material'

const Community = () => {
  return (
    <Container  sx={{py:2,height:"86vh",
      position:"relative"


    }}>
    <iframe title='Chatbot' src="https://organizations.minnit.chat/111407441485264/c/Main?embed&nickname=" style={{border:"none",width:"100%",height:"100%",
      position:"relative"}} allowTransparency="true">
    
    </iframe>
     
     </Container>
  )
}

export default Community
