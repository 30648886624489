import React from 'react'
import { Container } from '@mui/material'

const Blogpage = () => {
  return (
    <Container  sx={{py:2,height:"100vh"}}>
      <iframe title="BLOGS" src="https://blogs.unipace.in/" height="100%" width="100%" ></iframe>
       
       </Container>
  )
}

export default Blogpage
